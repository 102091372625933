import { useState, useEffect } from 'react';
import validator from 'validator';


//Number validation Hook
const useNumberVal = (number) => {
  const [data, setData] = useState();
 
  useEffect(() => {
    let errorClass = ""
    if (validator.isEmpty(number)) {
      errorClass = "is-invalid";
      setData({
        status: false,
        errorClass: errorClass,
        msg: 'Input is required'
      });

    } else if (!validator.matches(number, /^[0-9]*$/)) {
      errorClass = "is-invalid";

      setData({
        status: false,
        errorClass: errorClass,
        msg: 'Input is invalid'
      });

    } else {
      errorClass = " ";
      setData({
        status: true,
        errorClass: errorClass,
        msg: ''
      });
    }

  }, [number]);

  return [data];
}

//Radio button validation hook
const useRadioVal = (selectedValue) => {
  const [data, setData] = useState();

  useEffect(() => {
    let errorClass = "";
    if (validator.isEmpty(selectedValue)) {
      errorClass = "is-invalid";
      setData({
        status: false,
        errorClass: errorClass,
        msg: 'Please select an option'
      });
    } else {
      errorClass = "";
      setData({
        status: true,
        errorClass: errorClass,
        msg: ''
      });
    }
  }, [selectedValue]);

  return [data];
}

const useFloatNumberVal = (number) => {
  const [data, setData] = useState();

  useEffect(() => {
    let errorClass = "";
    if (validator.isEmpty(number)) {
      errorClass = "is-invalid";
      setData({
        status: false,
        errorClass: errorClass,
        msg: "Input is required"
      });
    } else if (!validator.matches(number, /^[0-9]*\.?[0-9]*$/)) {
      errorClass = "is-invalid";
      setData({
        status: false,
        errorClass: errorClass,
        msg: "Input is invalid"
      });
    } else {
      errorClass = " ";
      setData({
        status: true,
        errorClass: errorClass,
        msg: ""
      });
    }
  }, [number]);

  return [data];
};


export { useNumberVal, useRadioVal, useFloatNumberVal };