import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNumberVal, useRadioVal, useFloatNumberVal } from "./Hooks/FormValidation";
import { convertRate, xirr } from "node-irr";

const IrrCalculator = () => {
  const [totalEMIs, setTotalEmis] = useState("10");
  const [advanceEMIs, setAdvanceEMIs] = useState("2");
  const [subventionRate, setSubventionRate] = useState("8");
  const [flatInterestRate, setFlatInterestRate] = useState("");
  const [loanAmount, setLoanAmount] = useState("100000");
  const [emiAmount, setEmiAmount] = useState("");
  const [disburseAmt, setDisburseAmt] = useState("");
  // const [issuedDate, setIssuedDate] = useState(moment().format("YYYY-MM-DD"));
  const issuedDate = "2024-03-01";
  const [emiValues, setEmiValues] = useState([]);
  const [selectInterestRate, setSelInterestRate] = useState("subvention");
  const [xirrVal, setXirrVal] = useState("");
  const [subdisabled, setSubDisabled] = useState(false);
  const [flatdisabled, setFlatDisabled] = useState(true);

  //validation constants
  const [loanAmountReqValidation, setloanAmountReqValidation] = useState({ errorClass: '', msg: '' });
  const [loanAmountReqValRes] = useNumberVal(loanAmount);

  const [totalEmisReqValidation, setTotalEmisReqValidation] = useState({ errorClass: '', msg: '' });
  const [totalEmisReqValRes] = useNumberVal(totalEMIs);

  const [advanceEmisReqValidation, setAdvanceEmisReqValidation] = useState({ errorClass: '', msg: '' });
  const [advanceEmisReqValRes] = useNumberVal(advanceEMIs);
  
  const [radioButReqValidation, setRadioButReqValidation] = useState({ errorClass: '', msg: '' });
  const [radioButReqValRes] = useRadioVal(selectInterestRate);
  const [subvenReqValidation, setsubvenReqValidation] = useState({ errorClass: '', msg: '' });
  const [subvenReqValRes] = useFloatNumberVal(subventionRate);
  const [flatIntReqValidation, setflatIntReqValidation] = useState({ errorClass: '', msg: '' });
  const [flatIntReqValRes] = useFloatNumberVal(flatInterestRate);


  useEffect(() => { //Change Validation to empty or default state on input change
    setloanAmountReqValidation({ errorClass: '', msg: '' });
    setTotalEmisReqValidation({ errorClass: '', msg: '' });
    setAdvanceEmisReqValidation({ errorClass: '', msg: '' });
    setRadioButReqValidation({ errorClass: '', msg: '' });
    setflatIntReqValidation({ errorClass: '', msg: '' });
    setsubvenReqValidation({ errorClass: '', msg: '' });
  }, [loanAmount, totalEMIs, advanceEMIs, subventionRate, flatInterestRate]);

  // calculating the emiAmount
  useEffect(() => {
    const calculateEmiAmount = () => {
      if (selectInterestRate === "subvention") {
        const emiAmount = loanAmount / totalEMIs;
        setEmiAmount(emiAmount);
        const disburseamt =
          -loanAmount + emiAmount * advanceEMIs + (subventionRate * 0.01) * loanAmount;
        setDisburseAmt(disburseamt);
      }
      if (selectInterestRate === "flatintr") {
        const _emiamt =
          (Number(loanAmount) *
            (Number.parseFloat((flatInterestRate * 0.01)) *
              ((Number(totalEMIs) - Number(advanceEMIs)) / 12)) +
            Number(loanAmount)) /
          totalEMIs;
        const emiamt = Number.parseFloat(_emiamt).toFixed(2);
        setEmiAmount(emiamt);
        const disburseamt = -loanAmount + emiamt * advanceEMIs;
        setDisburseAmt(Number.parseFloat(disburseamt).toFixed(2));
      }
    };

    calculateEmiAmount();
  }, [loanAmount, totalEMIs, advanceEMIs, selectInterestRate, flatInterestRate, subventionRate]);

  // Function to handle changes in the selected option
  const handleOptionChange = (event) => {
    setSelInterestRate(event.target.value);
    if (event.target.value === "subvention") {
      setFlatDisabled(true);
      setSubDisabled('');
    }
    else {
      setSubDisabled(true);
      setFlatDisabled('');
    }
  };

  const calculateHandler = (e) => {
    e.preventDefault();
    let subventionStatus = true;
    let flatIntStatus = true;
    if (selectInterestRate === "subvention") {
      setsubvenReqValidation(subvenReqValRes);
      subventionStatus = subvenReqValRes.status;
    }
    else {
      setflatIntReqValidation(flatIntReqValRes);
      flatIntStatus = flatIntReqValRes.status;
    }
    setloanAmountReqValidation(loanAmountReqValRes);
    setTotalEmisReqValidation(totalEmisReqValRes);
    setAdvanceEmisReqValidation(advanceEmisReqValRes);
    setRadioButReqValidation(radioButReqValRes);
    // setsubvenReqValidation(subvenReqValRes);
    // setflatIntReqValidation(flatIntReqValRes);
    if (loanAmountReqValRes.status && totalEmisReqValRes.status && advanceEmisReqValRes.status && radioButReqValRes.status && subventionStatus && flatIntStatus) {
      // logic for when to be paid if 1-20 next month, 21-31 next + 1 month
      const remainingEMIs = totalEMIs - advanceEMIs;
      const emiArray = [];
      const currentDate = moment(issuedDate);
      const nextMonth = currentDate.month() + 1;
      const year = currentDate.year();
      let emiDate;
      // if (currentDate.date() <= 20) {
      emiDate = moment([year, nextMonth, 5]);
      // } else {
      // emiDate = moment([year, nextMonth + 1, 5]);
      // }
      // Push disbursement amount and issued date as the first element of the array
      emiArray.push({
        id: 0,
        amount: parseFloat(disburseAmt), // disbursement amount
        date: currentDate.format("YYYY/MM/DD"), // issued date
      });
      // Push remaining EMIs
      for (let i = 1; i <= remainingEMIs; i++) {
        emiArray.push({
          id: i,
          amount: parseFloat(emiAmount),
          date: emiDate.format("YYYY/MM/DD"),
        });
        emiDate.add(1, "months");
      }
      setEmiValues(emiArray);
      
      console.log(typeof emiArray, emiArray, "ar?");
      const _xirr = xirr(emiArray);
      const _rate = convertRate(_xirr.rate, 365);
      const finalRateYearly = Number.parseFloat(_rate * 100).toFixed(2);
      setXirrVal(finalRateYearly);
    }
  };

  const handleGenerateTable = (e) => {
    calculateHandler(e);
  };

  return (

    <div className="container p-5">
      <div className="card border border-none shadow-lg p-3 h-100 m-2 m-md-0">
        <div className="header text-white p-3">
          <h3>IRR Calculator</h3>
          <h6>Calculate Internal Rate of Return Online</h6>
        </div>
        <form className="mt-3 px-3">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="form-row pb-2">
                <label className="form-label">Initial Investment</label>
                <input type="text" className={"form-control " + loanAmountReqValidation.errorClass} value={loanAmount} onChange={(e) => setLoanAmount(e.target.value)} placeholder="e.g. 1,00,000" />
                <p className="val-response-text">{loanAmountReqValidation.msg}</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="form-row pb-3">
                <label className="form-label">Total Emis</label>
                <input type="text" className={"form-control " + totalEmisReqValidation.errorClass} value={totalEMIs} onChange={(e) => setTotalEmis(e.target.value)} placeholder="Total Emis" />
                <p className="val-response-text">{totalEmisReqValidation.msg}</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="form-row pb-2">
                <label className="form-label">Advance Emis</label>
                <input type="text" className={"form-control " + advanceEmisReqValidation.errorClass} value={advanceEMIs} onChange={(e) => setAdvanceEMIs(e.target.value)} placeholder="Advance Emis" />
                <p className="val-response-text">{advanceEmisReqValidation.msg}</p>
              </div>
            </div>
            <div className="col-12">
              <div className="form-row pb-2">
                <label className="form-label">Choose one :</label>
                <input
                  type="radio"
                  id="subvention"
                  value="subvention"
                  checked = {selectInterestRate === "subvention"}
                  onChange = {handleOptionChange}
                  className = {"ms-3 " + radioButReqValidation.errorClass}
                />
                <span className="ms-1 text-muted">Subvention</span>
                <input
                  type="radio"
                  id="flatintr"
                  value="flatintr"
                  checked = {selectInterestRate === "flatintr"}
                  onChange = {handleOptionChange}
                  className = {"ms-3 " + radioButReqValidation.errorClass}
                />
                <span className="ms-1 text-muted">Flat interest rate</span>
                <p className="val-response-text" >{radioButReqValidation.msg}</p>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-row pb-2">
                <label className="form-label">Subvention Rate</label>
                <input type="text" className={"form-control " + subvenReqValidation.errorClass} value={subventionRate} onChange={(e) => setSubventionRate(e.target.value)} placeholder="Enter Subvention rate..." disabled={subdisabled} />
                <p className="val-response-text" >{subvenReqValidation.msg}</p>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-row pb-2">
                <label className="form-label">Flat Interest Rate</label>
                <input type="text" className={"form-control " + flatIntReqValidation.errorClass} value={flatInterestRate} onChange={(e) => setFlatInterestRate(e.target.value)} placeholder="Enter Flatinterest rate..." disabled={flatdisabled} />
                <p className="val-response-text" >{flatIntReqValidation.msg}</p>
              </div>
            </div>

          </div>
        </form>
        <button type="button" className="btn btn-outline-success w-25 mt-3 mx-auto" onClick={handleGenerateTable}>Calculate</button>
        {xirrVal &&
          <div className="row mt-2">
            <div className="col-12 col-lg-5">
              <div className="table-container mt-4">
                <table className="table table-striped ms-lg-3 shadow">
                  <thead className="thead-dark">
                    <tr>
                      <th style={{ padding: '8px', backgroundColor: '#152847', color: '#ffffff' }}>EMI</th>
                      <th style={{ padding: '8px', backgroundColor: '#152847', color: '#ffffff' }}>EMI Amount</th>
                      {/* <th style={{ padding: '8px', backgroundColor: '#152847', color: '#ffffff' }}>EMI Date</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {emiValues.map((emi) => (
                      <tr key={emi.id} >
                        <td style={{ padding: '8px' }}>{emi.id}</td>
                        <td style={{ padding: '8px' }}>{emi.amount}</td>
                        {/* <td style={{ padding: '8px' }}>{emi.date}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-lg-6 ms-lg-4">
              <div className="xirr-container mt-4">
                <h4 className="text-muted">Results</h4>
                <hr className="new" />
                <div className="text-center pt-4">
                  <p className="result-text">Your effective IRR is....</p>
                  <div className="result-container"><h1 className="result">{xirrVal}%</h1></div>
                  <div className="result-des p-4 mt-4">Based on the series of cashflows, the equivalent interest rate you are paying for capital is {xirrVal} per annum</div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>

  );
};

export default IrrCalculator;
