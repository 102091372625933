import IrrCalculator from "./IrrCalci";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/Styles/Styles.css'

function App() {
  return (
    <div className="App">
      <header>
        <IrrCalculator />
      </header>
    </div>
  );
}

export default App;
